<template>
  <div class="background">
    <div class="top_logo">
      <van-image
          class="logo"
          :src="logo"
      />
      <p class="slogen">您好,欢迎使用街道果果小程序!</p>
    </div>
    <div style="align-items: center ;  justify-content: center ; " class="login">
      <van-row class="page-top-content">
<!--        <van-col span="8" offset="4">-->
<!--          <div class="fontSize" @click="belongPassword">-->
<!--            密码绑定-->
<!--          </div>-->
<!--          <div v-if="binding==1" class="xhx">-->
<!--          </div>-->
<!--        </van-col>-->
        <van-col span="24">
          <div class="fontSize" @click="belongCode">
            手机号绑定
          </div>
          <div v-if="binding==2" class="xhx">
          </div>
        </van-col>
      </van-row>
      <div v-if="binding==1" class='panel-col-center item-center qh'>
        <p class="input_title">账号</p>
        <div style=" position:relative; display: flex  ; align-items: center ;  justify-content: center ;">
          <van-field class='input' v-model="userName" placeholder="请输入账号" maxlength='12'
                     :center="true" clearable  />
          <!--      <van-icon size="20" class="ic"  name="user-circle-o"></van-icon>-->
        </div>
        <p  class="input_title">密码</p>
        <div style=" position:relative; display: flex  ; align-items: center ;  justify-content: center ;">
          <!--      <van-icon size="20" class="ic2" type="closed-eye"></van-icon>-->
          <van-field class='input' v-model="passWord" type="password" placeholder="请输入密码"
                     maxlength='20' :center="true" clearable  />
        </div>
        <div style="margin-top:0.95rem;">
          <van-button class='submit' type='primary' size="large" @click="submit">绑定账号</van-button>
        </div>

        <!--      <div class="yd">-->

        <!--          <label class="checkbox">-->
        <!--            <van-checkbox v-model ="agreed" shape="square"-->
        <!--                      class="ydbty" >-->
        <!--            阅读并同意-->
        <!--              <span @click="showAgreement" style="color:#2980b9;">《服务协议》</span>-->
        <!--            </van-checkbox>-->
        <!--          </label>-->
        <!--      </div>-->
      </div>

      <div v-else class='panel-col-center item-center qh'>
        <p  class="input_title">手机号</p>
        <div style=" display: flex  ; align-items: center ;  justify-content: center ;">
          <van-field class='input' v-model="mobile"  placeholder="请输入手机号" maxlength='12'
                     :center="true" clearable  />
        </div>
        <p  class="input_title">验证码</p>
        <div
            style=" position:relative;display: flex  ; align-items: center ;  justify-content: center ;">
          <van-field class='input' v-model="authCode"  placeholder="请输入验证码" maxlength='11'
                     :center="true" clearable  />
          <div :disabled='control.btnNotSend' @click='verifyMobile'
               style="position: absolute;color:#3890F9; font-size: 0.44rem; right: 15%;z-index: 9;">
            {{btnSendText}}
          </div>
        </div>
        <div class='padding-md' style="margin-top:0.95rem;">
          <van-button class='submit' type='primary' size="large" @click="verifyMobileSure">绑定账号</van-button>
        </div>

        <!--      <div class="yd">-->

        <!--        <label class="checkbox">-->
        <!--          <van-checkbox v-model ="agreed" shape="square"-->
        <!--                        class="ydbty" >-->
        <!--            阅读并同意-->
        <!--            <span @click="showAgreement" style="color:#2980b9;">《服务协议》</span>-->
        <!--          </van-checkbox>-->
        <!--        </label>-->
        <!--      </div>-->
      </div>
    </div>
    <div class="sm">
      该应用仅限于已经开通优户通基层数据协同云平台的社区工作者登录使用，社工的登录账号和密码由社区管理员在云平台的管理平台上设置。
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {getImageStream} from '@/utils/index'
import {twoDomainLogin} from '@/utils/common'
import http from '@/utils/httpRequest'
import { Toast } from 'vant';
import rsaUtils from "@/utils/rsaUtils";
var that
export default {
  data(){
    return{
      logo:require('@/assets/img/logo.png'),
      binding:2,
      canLogin: null,
      userName: '',
      passWord: '',
      userCode: '',
      agreed: false,
      mobile: "",
      authCode: "",
      code: "",
      appid: '',
      openid: '',
      btnSendText: "获取验证码",
      path: '',
      control:
          {
            btnNotSend:false
          }
    }
  },
  name: "login",
  created(options) {
    that = this;
    options =this.$route.query
    if (options.path) {
      this.path = options.path
    }
    // alert("***********=="+options.code)
    Toast.setDefaultOptions('loading', { duration: 0 });
    if ( options && options.code){
      that.code = options.code;
    }else if(that.$globalData.key){
      that.code = that.$globalData.key
    } else if (options && options.appid && options.openid) {
      that.appid = options.appid
      that.openid = options.openid
    }
  },
  methods: {

    getOpenId(){

    },

    belongPassword: function (e) {
      that.binding=1
    },
    belongCode: function (e) {
      that.binding=2
    },

    showAgreement: function () {
      that.$router.push('/agreement')
    },
    checkLogin (data) {
      if (data.code == 0) {
        Vue.prototype.$globalData.token=data.token
        Vue.prototype.$globalData.topToken=data.token
        Vue.prototype.$globalData.ddUser=data.ddUser
        if(data.homePageVO != null ) {
          http.changeUrl(data.homePageVO.domain);
          if(data.homePageVO.domain){
            twoDomainLogin(data.homePageVO, data.token)
            return
          }
          that.$globalData.userInfo = data.homePageVO
          Vue.prototype.$orgId=data.homePageVO.orgId
        }
        // wx.setStorageSync('uhtUserInfo', data.rows);
        for(let i in data.homePageVO){
          let value = data.homePageVO[i]
          if(!value){
            value =""
          }
        }
        if (this.path) {
          that.$router.push({path: '/home', query: {dd_nav_translucent: true }})
        } else {
          that.$router.push({path: '/home'})
        }
      } else {
        Toast.fail(data.msg);
      }
    },
    //点击登录并绑定微信方法
    submit: function (e) {
      // if (!that.agreed) {
      //   Toast.fail('请同意服务协议');
      //   // Toast.fail("请同意服务协议", 1500);
      //   return;
      // }
      // alert(that.code)
      if(!that.userName.trim()){
        Toast.fail('请输入账号！');
        return ;
      }

      if(!that.passWord.trim()){
        Toast.fail('请输入密码！');
        return ;
      }
      console.log(that.userName)
      if (that.code) {
        that.$http({
          url: that.$http.adornUrl(`/wxapp/dd/login`),
          method: 'post',
          data: {
            'mobile': rsaUtils.encrypt(that.userName),
            'password': rsaUtils.encrypt(that.passWord),
            'userCode': that.code
          }
        }).then(({data}) => {

          this.checkLogin(data)
        })
      } else if (that.appid && that.openid) {
        that.$http({
          url: that.$http.adornUrl(`/wxapp/dd/threeParties/Login`),
          method: 'post',
          data: {
            'mobile': that.userName,
            'password': that.passWord,
            'appId': that.appid,
            'openId': that.openid
          }
        }).then(({data}) => {
          this.checkLogin(data)
        })
      }
    },

    //验证手机号是否存在
    verifyUser(success) {
      if(!that.mobile.trim()){
        Toast.fail('请输入手机号！');
        return ;
      }
      that.$http({
        url: that.$http.adornUrl(`/wxapp/user/mobileVerifyUser`),
        method: 'get',
        params: that.$http.adornParams({ mobile: that.mobile})
      }).then(({data}) => {
        if (data.code == 0) {
          success(true)
        } else {
          Toast.fail(data.msg, 1500);
          success(false, data.msg)
        }
      })
    },
    //通知后端发送验证码
    verifyMobile() {
      that.verifyUser(function (success) {
        if (success) {
          Toast.loading({
            message: '发送中...',
            forbidClick: true,
          });

          that.$http({
            url: that.$http.adornUrl(`/general/verify/sendVerifyCode`),
            method: 'post',
            params: that.$http.adornParams({token: that.code, mobile: that.mobile, needExist: 1 })
          }).then(({data}) => {
            that.control.btnNotSend=true
            Toast.clear()
            if (data.code != 0) {
              Toast.fail(data.msg);
            } else {
              that.countDown();
            }
          })
        }

      })

    },
    //
    countDown: function () {
      let that = this;
      let countDownNum = 60;//获取倒计时初始值
      //如果将定时器设置在外面，那么用户就看不到countDownNum的数值动态变化，所以要把定时器存进data里面
      that.timer=setInterval(function () {//这里把setInterval赋值给变量名为timer的变量
          //每隔一秒countDownNum就减一，实现同步
          countDownNum--;
          //然后把countDownNum存进data，好让用户知道时间在倒计着
          that.btnSendText=countDownNum
          //在倒计时还未到0时，这中间可以做其他的事情，按项目需求来
          if (countDownNum == 0) {
            //这里特别要注意，计时器是始终一直在走的，如果你的时间为0，那么就要关掉定时器！不然相当耗性能
            //因为timer是存在data里面的，所以在关掉时，也要在data里取出后再关闭
            clearInterval(that.timer);
            //关闭定时器之后，可作其他处理codes go here
            that.control.btnNotSend= false

            that.btnSendText="刷新"
          }
        }, 1000)
    },
    //验证短信验证码
    verifyMobileSure() {
      if (that.authCode) {
        // if (!that.agreed) {
        //   Toast.fail("请同意服务协议");
        //   return;
        // }
        that.$http({
          url: that.$http.adornUrl(`/general/verify/verifyCode`),
          method: 'post',
          params: that.$http.adornParams({ token: that.code, code: that.authCode })
        }).then(({data}) => {
          if (data.code == 0) {
            //验证通过
            that.control.codePass=true
            //验证通过，登录
            that.$http({
              url: that.$http.adornUrl(`/wxapp/dd/mobileLogin`),
              method: 'post',
              params: that.$http.adornParams(
                  {mobile: that.mobile,
                userCode: that.code })
            }).then(({data}) => {
              if (data.code == 0) {
                that.$globalData.token = data.token
                that.$globalData.topToken = data.token
                Vue.prototype.$globalData.ddUser=data.ddUser
                if(data.homePageVO != null ) {
                  http.changeUrl(data.homePageVO.domain);
                  if(data.homePageVO.domain){
                    twoDomainLogin(data.homePageVO, data.token)
                    return
                  }
                }
                // wx.setStorageSync('uhtUserInfo', data.rows);
                that.$globalData.userInfo = data.homePageVO
                Vue.prototype.$orgId=data.homePageVO.orgId
                Vue.prototype.$needUpdatePassword = data.needUpdatePassword
                that.$router.push('/home')
              } else {
                Toast.fail(data.msg);
              }
            })
          } else {
            // Toast.fail(data.msg, 1500);
            Toast.fail("登录失败,请重启小程序!");
            that.control.codePass= false
          }
        })
      } else {
        Toast.fail("请输入验证码");
      }
    },
    //输入验证码触发
    authCodeInput: function (e) {
      that.authCode = e.detail.value;
    },
  }
}
</script>

<style lang="scss" scoped>
.background{
  width: 100%;
  height: 100vh;
  background-color: #fff;
  text-align: center;
  background-image: url('~@/assets/img/login_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 70px;
}
.top_logo {
  text-align: left;
}
.logo {
  margin-top: 200px;
  width: 195px;
}
.slogen {
  font-size: 30px;
  color: #333;
}
.input_title {
  font-size: 28px;
  color: #2E2E48;
  font-weight: bold;
  padding-left: 35px;
  text-align: left;
  margin: 20px 0 0;
}
.xhx{
  border-bottom: 6px solid #034cb9;
  width: 40px;
  margin: 15px auto 0 auto;
  border-radius: 7px;
}
.login {
  box-shadow: 0px 10px 80px 0px rgba(106, 162, 255, 0.3);
  padding: 40px 0;
  border-radius: 10px;
}
.fontSize{
  font-size: 32px;
}
.input{
  width: 70vw;
  height: 80px;
  line-height: 60px;
  text-align: center;
  font-size: 30px;
  color: #000;
  border-radius: 10px;
  //background: #f1f2f7;
  border-bottom: 2px solid #F3F5FA;
}
::v-deep .van-cell {
  padding: 0;
}
.panel-col-center {
  float: none;
  justify-content: center;
  flex-flow: column;
}
::v-deep .van-field__left-icon .van-icon {
  display: block;
  font-size: 45px !important;
  line-height: inherit;
}
::v-deep .van-checkbox{
  justify-content: center;
}
.submit{
  width: 70vw;
  background-color: #0471FD !important;
  font-size: 30px;
  color: #fff;
  height: 80px;
  line-height: 40px;
  border-radius: 40px;
  border-color: #4f85ff
}
.qh{
  border-radius:5px;
  background-color: #fff;
  margin: 20px 10px;
  position:relative;
}
.yd{
  font-size:24px;
  margin-top: 33px;
}
.ydbty{
  transform: scale(0.8,0.8);
  vertical-align: middle;
  margin-bottom: 6px;
}
.sm{
  font-size:22px;
  margin: 45px 0;
  text-align: left;
  color: #8a979e;
}
</style>
